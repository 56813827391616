//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'accountDetail',
  data: function data() {
    return {
      size: '',
      userInfo: {},
      walletInfo: {}
    };
  },
  mounted: function mounted() {
    this.getUserInfo();
    this.getWalletInfo();
  },
  methods: {
    back: function back() {
      this.$router.go(-1);
    },
    getUserInfo: function getUserInfo() {
      var _this = this;
      this.$http.Get(this.$api.userInfo, {
        userId: this.$route.query.userId
      }).then(function (res) {
        if (res.code == 200) {
          _this.userInfo = res.data;
        }
      });
    },
    getWalletInfo: function getWalletInfo() {
      var _this2 = this;
      this.$http.Post(this.$api.getMyWallet, {
        userId: this.$route.query.userId
      }).then(function (res) {
        if (res.code == 200) {
          _this2.walletInfo = res.data;
        }
      });
    }
  }
};